import React, { useState, useEffect } from "react"
import "./ice-paragraph.scss"


const IceParagraph = ({ props }) => {

    // const text = "We have partnered with Vast Visibility, a leading provider of technology solutions to the UK insurance sector. Together we monitor market trends and consumer feedback to ensure that our customer journey remains as smooth and easy-to-use as possible, so that the buying experience is the best we can make it. All insurers providing quotations through our website are regulated entities operating to the highest standards, so giving our customers peace of mind from the outset and at the conclusion of their insurance buying journey.";

    // const [visibleLetters, setVisibleLetters] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setVisibleLetters(prevValue => {
    //             if (prevValue < text.length) {
    //                 return prevValue + 1;
    //             } else {
    //                 clearInterval(interval);
    //                 return prevValue;
    //             }
    //         });
    //     }, 100);

    //     return () => clearInterval(interval);
    // }, [text]);

    return (
        <div>
            <div className="ice-paragraph-container">
                <div uk-scrollspy="cls: uk-animation-slide-left-small; target: .title-slide; delay: 500;"><h1 className="title"><span className="title-slide">{props.title}</span></h1></div>
                <div className="paragraph-content">
                    <div className="left-column">
                        {props.leftSide.map((itemLeft, indexLeft) => {
                            return (
                                <p>{itemLeft.text}</p>
                            )
                        })
                        }
                    </div>
                    <div className="right-column">
                        {props.rightSide.map((itemRight, indexRight) => {
                            return (
                                <p>{itemRight.text}</p>
                            )
                        })
                        }

                        {/* <div className="fade-in-text">
                            {text.split('').map((letter, index) => (
                                <span key={index} className={index < visibleLetters ? 'visible' : ''}>
                                    {letter}
                                </span>
                            ))}
                        </div> */}

                    </div>
                </div>
            </div>
        </div>

    )

}

export default IceParagraph