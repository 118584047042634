import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import IceHeader from "../components/ice-header/ice-header.js";
import IceParagraph from "../components/ice-paragraph/ice-paragraph.js";
import IceBrandCard from "../components/ice-brand-card/ice-brand-card.js";
import IceContact from "../components/ice-contact-component/ice-contact-component.js";
import { homeInfo } from "../utils/information.js";


const IndexPage = () => {


  return (
    <Layout
      SEOTitle={"Home page - ICE"}
      SEODescription={"Our goal is to find every visitor their cheapest insurance price in the easiest possible way!"}
      footerDisclaimer={""}>

      <IceHeader />
      <IceParagraph props={homeInfo} />
      <IceBrandCard />
      <IceContact />

    </Layout>
  )

}

export default IndexPage