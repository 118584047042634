import React from "react"
import "./ice-header.scss"


const IceHeader = () => {


    return (
        <div>
            <div className="ice-header-container">
                <div className="background"></div>
                <div className="header-content">
                    <div className="content">
                        <p>Our goal is to find every visitor their cheapest insurance price in the easiest possible way!</p>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default IceHeader