import React from "react"
import "./ice-contact-component.scss"
import logo from "../../assets/ice-logo.webp";


const IceContact = () => {


    return (
        <div style={{ background: "#e9f6fe" }}>
            <div className="ice-contact-container">
                <div className="left-column">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.969899464682!2d-2.104321048662597!3d49.18215778570739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480c4d5fa63f1c03%3A0xac03355a57c3d275!2sGrenville+St%2C+St+Helier%2C+Jersey!5e0!3m2!1sen!2sro!4v1555339588668!5m2!1sen!2sro" title="ICE Map Details" width="950" height="500" frameBorder="0" allowFullScreen=""></iframe>
                    {/* on mobile - no longer used */}
                    {/* <a href="/contact-us/" className="button-mobile" target="_self">More ways to contact ICE</a> */}
                </div>

                <div className="right-column">
                    <h1 className="title">Contact us</h1>
                    {/* logo ICE */}
                    <img src={logo} alt="ICE Logo"/> 

                    <div className="address-container">
                        <span className="address-text">Address: </span>
                        <p>IFC 5, St Helier, Jersey JE1 1ST</p>
                    </div>

                    <div className="phone-container">
                        <span className="phone-text">Telephone: </span>
                        <a href="tel:01534712900" className="external">01534 712900</a>
                    </div>

                    {/* on mobile - no longer used */}
                    {/* <a href="tel:01534 712900" class="phone-button-mobile" data-event="Call ICE - ClickToCall">Call ICE</a> */}

                    <div className="email-container">
                        <span className="email-text">Email: </span>
                        <a href="mailto:accounts@icecomparison.com" className="external">accounts@icecomparison.com</a>
                    </div>

                    {/* on mobile - no longer used*/}
                    {/* <a href="mailto:accounts@icecomparison.com" class="email-button-mobile">Email ICE</a> */}

                    <a href="https://www.icecomparison.com/contact-us/" className="button" target="_self">More ways to contact ICE</a>
                </div>
            </div>
        </div>
    )

}

export default IceContact