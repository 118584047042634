import React from "react"
import "./ice-brand-card.scss"
import ti from '../../assets/TI-white.svg'
import tvi from '../../assets/TVI-white.svg'
import tbi from '../../assets/TBI-white.svg'



const IceBrandCard = () => {


    return (
        <div className="ice-card-container">
            <div className="card-content">
                <h2 className="title">Our Brands</h2>
                <div className="content">

                    <p>The Company carries out the management of a number of price comparison websites for general insurance.</p>
                    <p>Our websites include:</p>

                </div>

                {/* logos TVI, TBI, TI */}
                <div>
                    <div className="images-wrapper" uk-scrollspy="cls: uk-animation-scale-up; target: img; delay: 500;">
                        <img src={tvi} alt="The Van Insurer Logo" />
                        <img src={tbi} alt="The Bike Insurer Logo" />
                        <img src={ti} alt="The Insurers Logo" />
                    </div>
                </div>
                <a href="/our-brands/" className="button" target="_self">Get the Details</a>
            </div>
        </div>

    )

}

export default IceBrandCard